import { callAuthApi } from '../../helpers/callApi';

const initialState = [];

const PROVEEDORES_REQUEST = 'PROVEEDORES_REQUEST';
const PROVEEDORES_SUCCESS = 'PROVEEDORES_SUCCESS';
const PROVEEDORES_FAILURE = 'PROVEEDORES_FAILURE';

const CREATE_PROVEEDOR_REQUEST = 'CREATE_PROVEEDOR_REQUEST';
const CREATE_PROVEEDOR_SUCCESS = 'CREATE_PROVEEDOR_SUCCESS';
const CREATE_PROVEEDOR_FAILURE = 'CREATE_PROVEEDOR_FAILURE';

const EDIT_PROVEEDOR_REQUEST = 'EDIT_PROVEEDOR_REQUEST';
const EDIT_PROVEEDOR_SUCCESS = 'EDIT_PROVEEDOR_SUCCESS';
const EDIT_PROVEEDOR_FAILURE = 'EDIT_PROVEEDOR_FAILURE';

const getProveedores = () => (dispatch) => {
    var endpoint = 'proveedores';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [PROVEEDORES_REQUEST, PROVEEDORES_SUCCESS, PROVEEDORES_FAILURE]
    });
}

const createProveedor = proveedor => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'proveedores/crear',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(proveedor),
        types: [CREATE_PROVEEDOR_REQUEST, CREATE_PROVEEDOR_SUCCESS, CREATE_PROVEEDOR_FAILURE]
    });
};

const editProveedor = proveedor => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'proveedores/editar',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(proveedor),
        types: [EDIT_PROVEEDOR_REQUEST, EDIT_PROVEEDOR_SUCCESS, EDIT_PROVEEDOR_FAILURE]
    });
};

export const proveedoresActions = {
    GET_PROVEEDORES: getProveedores,
    CREATE_PROVEEDOR: createProveedor,
    EDIT_PROVEEDOR: editProveedor
}

export function proveedoresReducer(state = initialState, action) {
    switch (action.type) {
        case PROVEEDORES_SUCCESS:
            return action.payload;
        case CREATE_PROVEEDOR_SUCCESS:
            return [...state, action.payload];
        case EDIT_PROVEEDOR_SUCCESS:
            return state.map(p => p.proveedorId === action.payload.proveedorId ? action.payload : p)
        default:
            return state;
    }
}
