import { callAuthApi } from '../../helpers/callApi';
import { CREATE_OPERACION_SUCCESS } from './operaciones';
import { CREATE_COMPRA_SUCCESS } from './compras';

const initialState = [];

const PRODUCTOS_REQUEST = 'PRODUCTOS_REQUEST';
const PRODUCTOS_SUCCESS = 'PRODUCTOS_SUCCESS';
const PRODUCTOS_FAILURE = 'PRODUCTOS_FAILURE';

const CREATE_PRODUCTO_REQUEST = 'CREATE_PRODUCTO_REQUEST';
const CREATE_PRODUCTO_SUCCESS = 'CREATE_PRODUCTO_SUCCESS';
const CREATE_PRODUCTO_FAILURE = 'CREATE_PRODUCTO_FAILURE';

const EDIT_PRODUCTO_REQUEST = 'EDIT_PRODUCTO_REQUEST';
const EDIT_PRODUCTO_SUCCESS = 'EDIT_PRODUCTO_SUCCESS';
const EDIT_PRODUCTO_FAILURE = 'EDIT_PRODUCTO_FAILURE';

const TIPO_OPERACION_VENTA = 1;
const TIPO_OPERACION_PRESUPUESTO = 3;

const getProductos = () => (dispatch) => {
    var endpoint = 'productos';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [PRODUCTOS_REQUEST, PRODUCTOS_SUCCESS, PRODUCTOS_FAILURE]
    });
}

const createProducto = producto => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'productos/crear',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(producto),
        types: [CREATE_PRODUCTO_REQUEST, CREATE_PRODUCTO_SUCCESS, CREATE_PRODUCTO_FAILURE]
    });
};

const editProducto = producto => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'productos/editar',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(producto),
        types: [EDIT_PRODUCTO_REQUEST, EDIT_PRODUCTO_SUCCESS, EDIT_PRODUCTO_FAILURE]
    });
};

export const productosActions = {
    GET_PRODUCTOS: getProductos,
    CREATE_PRODUCTO: createProducto,
    EDIT_PRODUCTO: editProducto
}

export function productosReducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCTOS_SUCCESS:
            return action.payload;
        case CREATE_PRODUCTO_SUCCESS:
            return [...state, action.payload];
        case EDIT_PRODUCTO_SUCCESS:
            return state.map(p => p.productoId === action.payload.productoId ? action.payload : p);
        case CREATE_OPERACION_SUCCESS:
            const isVenta =
                action.payload.tipoOperacionId === TIPO_OPERACION_VENTA ||
                action.payload.tipoOperacionId === TIPO_OPERACION_PRESUPUESTO;
            return state.map(p => {
                const detalle = action.payload.productos.find(x => x.productoId === p.productoId);
                if (detalle) {
                    p.stock = p.stock + detalle.cantidad * (isVenta ? -1 : 1);
                }
                return p;
            });
        case CREATE_COMPRA_SUCCESS:
            return state.map(p => {
                const detalle = action.payload.productos.find(x => x.productoId === p.productoId);
                if (detalle) {
                    p.stock = p.stock + detalle.cantidad;
                }
                return p;
            });
        default:
            return state;
    }
}
