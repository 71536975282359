import { combineReducers } from 'redux';

import { productosReducer } from './stores/productos';
import { clientesReducer } from './stores/clientes';
import { tiposResponsableReducer } from './stores/tiposResponsable';
import { categoriasProductoReducer } from './stores/categoriasProducto';
import { carritoVentaReducer } from './stores/carritoVenta';
import { carritoCompraReducer } from './stores/carritoCompra';
import { operacionesReducer } from './stores/operaciones';
import { webServiceInfoReducer } from './stores/webServiceInfo';
import { afipConfigurationReducer } from './stores/afipConfiguration';
import { authenticationReducer } from './stores/authentication';
import { proveedoresReducer } from './stores/proveedores';
import { comprasReducer } from './stores/compras';
import { dolarReducer } from './stores/dolar';

const rootReducer = combineReducers({
    productos: productosReducer,
    clientes: clientesReducer,
    tiposResponsable: tiposResponsableReducer,
    categoriasProducto: categoriasProductoReducer,
    carritoVenta: carritoVentaReducer,
    carritoCompra: carritoCompraReducer,
    operaciones: operacionesReducer,
    webServiceInfo: webServiceInfoReducer,
    afipConfiguration: afipConfigurationReducer,
    authentication: authenticationReducer,
    proveedores: proveedoresReducer,
    compras: comprasReducer,
    dolar: dolarReducer
});

export default rootReducer;