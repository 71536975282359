import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
    AppBreadcrumb,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarNav
} from '@coreui/react';

import navigation from '../navigation';

import routes from '../routes';

import Header from './Header';

class Layout extends Component {
    componentDidMount() {
        window.addEventListener('beforeunload', this.handleLeavePage);
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleLeavePage);
    }
    handleLeavePage(e) {
        const confirmationMessage = '';
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;              // Gecko, WebKit, Chrome <34
    }
    render() {
        return (
            <div className="app">
                <AppHeader fixed>
                    <Header />
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                        <AppSidebarForm />
                        <AppSidebarNav navConfig={navigation} {...this.props} />
                        <AppSidebarFooter />
                    </AppSidebar>
                    <main className="main">
                        <AppBreadcrumb appRoutes={routes} />
                        <Container fluid>
                            <Switch>
                                {routes.map((route, idx) => {
                                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                        <route.component {...props} />
                                    )} />)
                                        : (null);
                                },
                                )}
                                <Redirect from="/" to="/venta" />
                            </Switch>
                        </Container>
                    </main>
                </div>
                {/* <AppFooter>
                    <DefaultFooter />
                </AppFooter> */}
            </div>
        );
    }
}

export default Layout;
