import React, { Component, Fragment } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import Layout from './components/Layout';
import Login from './components/Login';
import { NotificationContainer } from 'react-notifications';

class App extends Component {
    render() {
        return (
            <HashRouter>
                <Fragment>
                    <NotificationContainer />
                    <Switch>
                        <Route exact path="/login" name="Login Page" component={Login} />
                        <PrivateRoute path="/" name="Home" component={Layout} />
                    </Switch>
                </Fragment>
            </HashRouter>
        );
    }
}

export default App;