import { productosActions } from './stores/productos';
import { clientesActions } from './stores/clientes';
import { tiposResponsableActions } from './stores/tiposResponsable';
import { categoriasProductoActions } from './stores/categoriasProducto';
import { carritoVentaActions } from './stores/carritoVenta';
import { carritoCompraActions } from './stores/carritoCompra';
import { operacionesActions } from './stores/operaciones';
import { webServiceInfoActions } from './stores/webServiceInfo';
import { afipConfigurationActions } from './stores/afipConfiguration';
import { authenticationActions } from './stores/authentication';
import { proveedoresActions } from './stores/proveedores';
import { comprasActions } from './stores/compras';
import { dolarActions } from './stores/dolar';

export default {
    PRODUCTOS: productosActions,
    CLIENTES: clientesActions,
    TIPOS_RESPONSABLE: tiposResponsableActions,
    CATEGORIAS_PRODUCTO: categoriasProductoActions,
    CARRITO_VENTA: carritoVentaActions,
    CARRITO_COMPRA: carritoCompraActions,
    OPERACIONES: operacionesActions,
    WEBSERVICEINFO: webServiceInfoActions,
    AFIPCONFIGURATION: afipConfigurationActions,
    AUTHENTICATION: authenticationActions,
    PROVEEDORES: proveedoresActions,
    COMPRAS: comprasActions,
    DOLAR: dolarActions
}