import React from 'react';
import Loadable from 'react-loadable';

import Layout from './components/Layout';

function Loading() {
    return <span>Cargando... <i className="fa fa-spin fa-spinner" /></span>;
}

const Tablero = Loadable({ loader: () => import('./components/Tablero'), loading: Loading });
const Venta = Loadable({ loader: () => import('./components/operaciones/Venta'), loading: Loading });
const Devolucion = Loadable({ loader: () => import('./components/operaciones/Devolucion'), loading: Loading });
const Productos = Loadable({ loader: () => import('./components/productos/Lista'), loading: Loading });
const NuevoProducto = Loadable({ loader: () => import('./components/productos/Nuevo'), loading: Loading });
const EditarProducto = Loadable({ loader: () => import('./components/productos/Editar'), loading: Loading });
const Categorias = Loadable({ loader: () => import('./components/categorias/Lista'), loading: Loading });
const NuevaCategoria = Loadable({ loader: () => import('./components/categorias/Nueva'), loading: Loading });
const EditarCategoria = Loadable({ loader: () => import('./components/categorias/Editar'), loading: Loading });
const Clientes = Loadable({ loader: () => import('./components/clientes/Lista'), loading: Loading });
const NuevoCliente = Loadable({ loader: () => import('./components/clientes/Nuevo'), loading: Loading });
const EditarCliente = Loadable({ loader: () => import('./components/clientes/Editar'), loading: Loading });
const Comprobante = Loadable({ loader: () => import('./components/operaciones/Comprobante'), loading: Loading });
const Operaciones = Loadable({ loader: () => import('./components/operaciones/Lista'), loading: Loading });
const Proveedores = Loadable({ loader: () => import('./components/proveedores/Lista'), loading: Loading });
const NuevoProveedor = Loadable({ loader: () => import('./components/proveedores/Nuevo'), loading: Loading });
const EditarProveedor = Loadable({ loader: () => import('./components/proveedores/Editar'), loading: Loading });
const Compras = Loadable({ loader: () => import('./components/compras/Lista'), loading: Loading });
const DetalleCompra = Loadable({ loader: () => import('./components/compras/Detalle'), loading: Loading });
const Compra = Loadable({ loader: () => import('./components/compras/Compra'), loading: Loading });
const Presupuesto = Loadable({ loader: () => import('./components/operaciones/Presupuesto'), loading: Loading });
const Dolar = Loadable({ loader: () => import('./components/Dolar'), loading: Loading });

const routes = [
    { path: '/', exact: true, name: 'Home', component: Layout },
    { path: '/tablero', name: 'Tablero', component: Tablero },
    { path: '/venta', name: 'Venta', component: Venta },
    { path: '/devolucion/:operacionId', name: 'Devolucion', component: Devolucion },
    { path: '/productos/nuevo', name: 'Nuevo Producto', component: NuevoProducto },
    { path: '/productos/:productoId', name: 'Editar Producto', component: EditarProducto },
    { path: '/productos', name: 'Productos', component: Productos },
    { path: '/categorias/nueva', name: 'Nueva Categoría', component: NuevaCategoria },
    { path: '/categorias/:categoriaProductoId', name: 'Editar Categoría', component: EditarCategoria },
    { path: '/categorias', name: 'Categorías', component: Categorias },
    { path: '/clientes/nuevo', name: 'Nuevo Cliente', component: NuevoCliente },
    { path: '/clientes/:clienteId', name: 'Editar Cliente', component: EditarCliente },
    { path: '/clientes', name: 'Clientes', component: Clientes },
    { path: '/operaciones/:operacionId', name: 'Detalle', component: Comprobante },
    { path: '/operaciones', name: 'Operaciones', component: Operaciones },
    { path: '/proveedores/nuevo', name: 'Nuevo Proveedor', component: NuevoProveedor },
    { path: '/proveedores/:proveedorId', name: 'Editar Proveedor', component: EditarProveedor },
    { path: '/proveedores', name: 'Proveedores', component: Proveedores },
    { path: '/compras/nueva', name: 'Nueva Compra', component: Compra },
    { path: '/compras/:compraId', name: 'Detalle', component: DetalleCompra },
    { path: '/compras', name: 'Compras', component: Compras },
    { path: '/presupuesto', name: 'Presupuesto', component: Presupuesto },
    { path: '/dolar', name: 'Dolar', component: Dolar }
];

export default routes;
