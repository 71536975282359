import { callAuthApi } from '../../helpers/callApi';

const initialState = [];

const CATEGORIAS_PRODUCTO_REQUEST = 'CATEGORIAS_PRODUCTO_REQUEST';
const CATEGORIAS_PRODUCTO_SUCCESS = 'CATEGORIAS_PRODUCTO_SUCCESS';
const CATEGORIAS_PRODUCTO_FAILURE = 'CATEGORIAS_PRODUCTO_FAILURE';

const CREATE_CATEGORIA_PRODUCTO_REQUEST = 'CREATE_CATEGORIA_PRODUCTO_REQUEST';
const CREATE_CATEGORIA_PRODUCTO_SUCCESS = 'CREATE_CATEGORIA_PRODUCTO_SUCCESS';
const CREATE_CATEGORIA_PRODUCTO_FAILURE = 'CREATE_CATEGORIA_PRODUCTO_FAILURE';

const EDIT_CATEGORIA_PRODUCTO_REQUEST = 'EDIT_CATEGORIA_PRODUCTO_REQUEST';
const EDIT_CATEGORIA_PRODUCTO_SUCCESS = 'EDIT_CATEGORIA_PRODUCTO_SUCCESS';
const EDIT_CATEGORIA_PRODUCTO_FAILURE = 'EDIT_CATEGORIA_PRODUCTO_FAILURE';

const getCategoriasProducto = () => (dispatch) => {
    var endpoint = 'categoriasproducto';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [CATEGORIAS_PRODUCTO_REQUEST, CATEGORIAS_PRODUCTO_SUCCESS, CATEGORIAS_PRODUCTO_FAILURE]
    });
}

const createCategoriaProducto = categoria => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'categoriasproducto',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(categoria),
        types: [CREATE_CATEGORIA_PRODUCTO_REQUEST, CREATE_CATEGORIA_PRODUCTO_SUCCESS, CREATE_CATEGORIA_PRODUCTO_FAILURE]
    });
};

const editCategoriaProducto = categoria => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'categoriasproducto/editar',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(categoria),
        types: [EDIT_CATEGORIA_PRODUCTO_REQUEST, EDIT_CATEGORIA_PRODUCTO_SUCCESS, EDIT_CATEGORIA_PRODUCTO_FAILURE]
    });
};

export const categoriasProductoActions = {
    GET_CATEGORIAS_PRODUCTO: getCategoriasProducto,
    CREATE_CATEGORIA_PRODUCTO: createCategoriaProducto,
    EDIT_CATEGORIA_PRODUCTO: editCategoriaProducto
}

export function categoriasProductoReducer(state = initialState, action) {
    switch (action.type) {
        case CATEGORIAS_PRODUCTO_SUCCESS:
            return action.payload;
        case CREATE_CATEGORIA_PRODUCTO_SUCCESS:
            return [...state, action.payload];
        case EDIT_CATEGORIA_PRODUCTO_SUCCESS:
            return state.map(p => p.categoriaProductoId === action.payload.categoriaProductoId ? action.payload : p);
        default:
            return state;
    }
}
