export default {
    items: [
        {
            name: 'Venta',
            url: '/venta',
            icon: 'icon-basket',
        },
        {
            name: 'Presupuesto',
            url: '/presupuesto',
            icon: 'icon-wallet',
        },
        {
            name: 'Listas',
            icon: 'icon-layers',
            children: [
                {
                    name: 'Productos',
                    url: '/productos',
                    icon: 'icon-drawer',
                },
                {
                    name: 'Categorías',
                    url: '/categorias',
                    icon: 'icon-grid',
                },
                {
                    name: 'Clientes',
                    url: '/clientes',
                    icon: 'icon-people',
                },
                {
                    name: 'Proveedores',
                    url: '/proveedores',
                    icon: 'icon-globe',
                },
                {
                    name: 'Compras',
                    url: '/compras',
                    icon: 'icon-present',
                }
            ]
        },
        {
            name: 'Reportes',
            icon: 'icon-book-open',
            children: [
                {
                    name: 'Operaciones',
                    url: '/operaciones',
                    icon: 'icon-docs'
                },
                {
                    name: 'Compras',
                    url: '/compras',
                    icon: 'icon-present'
                }
            ]
        },
        {
            name: 'Controles',
            icon: 'icon-wrench',
            children: [
                {
                    name: 'Dólar',
                    url: '/dolar',
                    icon: 'icon-calculator'
                },
                {
                    name: 'Tablero',
                    url: '/tablero',
                    icon: 'icon-speedometer'
                }
            ]
        }
    ]
};
