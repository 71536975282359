import { callAuthApi } from '../../helpers/callApi';

const initialState = [];

const TIPOS_RESPONSABLE_REQUEST = 'TIPOS_RESPONSABLE_REQUEST';
const TIPOS_RESPONSABLE_SUCCESS = 'TIPOS_RESPONSABLE_SUCCESS';
const TIPOS_RESPONSABLE_FAILURE = 'TIPOS_RESPONSABLE_FAILURE';

const getTiposResponsable = () => (dispatch) => {
    var endpoint = 'tiposresponsable';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [TIPOS_RESPONSABLE_REQUEST, TIPOS_RESPONSABLE_SUCCESS, TIPOS_RESPONSABLE_FAILURE]
    });
}

export const tiposResponsableActions = {
    GET_TIPOS_RESPONSABLE: getTiposResponsable
}

export function tiposResponsableReducer(state = initialState, action) {
    switch (action.type) {
        case TIPOS_RESPONSABLE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
