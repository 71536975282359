import { callAuthApi } from '../../helpers/callApi';

const initialState = null;

const AFIP_CONFIGURATION_REQUEST = 'AFIP_CONFIGURATION_REQUEST';
const AFIP_CONFIGURATION_SUCCESS = 'AFIP_CONFIGURATION_SUCCESS';
const AFIP_CONFIGURATION_FAILURE = 'AFIP_CONFIGURATION_FAILURE';

const getAfipConfiguration = () => (dispatch) => {
    var endpoint = 'test/afipconfiguration';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [AFIP_CONFIGURATION_REQUEST, AFIP_CONFIGURATION_SUCCESS, AFIP_CONFIGURATION_FAILURE]
    });
}

export const afipConfigurationActions = {
    GET_AFIP_CONFIGURATION: getAfipConfiguration
}

export function afipConfigurationReducer(state = initialState, action) {
    switch (action.type) {
        case AFIP_CONFIGURATION_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
