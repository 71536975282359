import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

export default class Header extends Component {
    logout() {
        localStorage.removeItem('user');
        window.location = '#/login';
    }
    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        return (
            <React.Fragment>
                <AppSidebarToggler className="d-lg-none" display="md" mobile />
                <AppNavbarBrand full={{ src: user.empresa.logoUrl, width: 89, height: 25, alt: 'Radiosur Logo' }} />
                <AppSidebarToggler className="d-md-down-none" display="lg" />

                <Nav className="ml-auto" navbar>
                    <AppHeaderDropdown direction="down">
                        <DropdownToggle nav>
                            <i className="icon icon-user" />
                        </DropdownToggle>
                        <DropdownMenu right style={{ right: 'auto' }}>
                            <DropdownItem header tag="div" className="text-center"><strong>{user.firstName} {user.lastName}</strong></DropdownItem>
                            <DropdownItem onClick={() => this.logout()}><i className="icon icon-lock" /> Salir</DropdownItem>
                        </DropdownMenu>
                    </AppHeaderDropdown>
                </Nav>
            </React.Fragment>
        );
    }
}