import { callAuthApi } from '../../helpers/callApi';

const initialState = [];

const GET_COMPRAS_REQUEST = 'GET_COMPRAS_REQUEST';
const GET_COMPRAS_SUCCESS = 'GET_COMPRAS_SUCCESS';
const GET_COMPRAS_FAILURE = 'GET_COMPRAS_FAILURE';

const CREATE_COMPRA_REQUEST = 'CREATE_COMPRA_REQUEST';
export const CREATE_COMPRA_SUCCESS = 'CREATE_COMPRA_SUCCESS';
const CREATE_COMPRA_FAILURE = 'CREATE_COMPRA_FAILURE';

const getCompras = () => (dispatch) => {
    var endpoint = 'compras';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [GET_COMPRAS_REQUEST, GET_COMPRAS_SUCCESS, GET_COMPRAS_FAILURE]
    });
}

const createCompra = compra => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'compras',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(compra),
        types: [CREATE_COMPRA_REQUEST, CREATE_COMPRA_SUCCESS, CREATE_COMPRA_FAILURE]
    });
};

export const comprasActions = {
    GET_COMPRAS: getCompras,
    CREATE_COMPRA: createCompra
}

export function comprasReducer(state = initialState, action) {
    switch (action.type) {
        case GET_COMPRAS_SUCCESS:
            return action.payload;
        case CREATE_COMPRA_SUCCESS:
            return [action.payload, ...state];
        default:
            return state;
    }
}
