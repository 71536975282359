import { callAuthApi } from '../../helpers/callApi';

const initialState = null;

const GET_DOLAR_REQUEST = 'GET_DOLAR_REQUEST';
const GET_DOLAR_SUCCESS = 'GET_DOLAR_SUCCESS';
const GET_DOLAR_FAILURE = 'GET_DOLAR_FAILURE';

const UPDATE_DOLAR_REQUEST = 'UPDATE_DOLAR_REQUEST';
const UPDATE_DOLAR_SUCCESS = 'UPDATE_DOLAR_SUCCESS';
const UPDATE_DOLAR_FAILURE = 'UPDATE_DOLAR_FAILURE';

const getDolar = () => (dispatch) => {
    var endpoint = 'dolar';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [GET_DOLAR_REQUEST, GET_DOLAR_SUCCESS, GET_DOLAR_FAILURE]
    });
}

const updateDolar = cotizacion => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'dolar',
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cotizacion),
        types: [UPDATE_DOLAR_REQUEST, UPDATE_DOLAR_SUCCESS, UPDATE_DOLAR_FAILURE]
    });
};

export const dolarActions = {
    GET_DOLAR: getDolar,
    UPDATE_DOLAR: updateDolar
}

export function dolarReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DOLAR_SUCCESS:
        case UPDATE_DOLAR_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
