import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import ACTIONS from '../store/actions';
import { NotificationManager } from 'react-notifications';

import { Formik } from 'formik';

const mapDispatchToProps = ({
    login: ACTIONS.AUTHENTICATION.LOGIN
});

class Login extends Component {
    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="4">
                            <Card className="p-4">
                                <CardBody>
                                    <h1 style={{ textAlign: "center" }}>RADIOSUR GESTI&Oacute;N</h1>
                                    <p className="text-muted">Ingrese sus datos</p>
                                    <Formik
                                        initialValues={{ username: '', password: '' }}
                                        validate={values => {
                                            const errors = {};
                                            if (!values.username) {
                                                errors.username = 'Required';
                                            }
                                            if (!values.password) {
                                                errors.password = 'Required';
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            this.props.login(values)
                                                .then(() => {
                                                    this.props.history.push('/')
                                                }).catch(response => {
                                                    const message = response.error ? response.error.message : 'Error al ingresar';
                                                    NotificationManager.error(message);
                                                    setSubmitting(false);
                                                });
                                        }}
                                        render={({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            setFieldTouched,
                                            isSubmitting
                                        }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="icon-user"></i>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="text"
                                                            name="username"
                                                            placeholder="Usuario"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.username}
                                                            className={errors.username && touched.username ? 'form-control is-invalid' : 'form-control'} />
                                                        {touched.username && errors.username && <div className="invalid-feedback">{errors.username}</div>}
                                                    </InputGroup>
                                                    <InputGroup className="mb-4">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="icon-lock"></i>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="password"
                                                            name="password"
                                                            placeholder="Clave"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            className={errors.password && touched.password ? 'form-control is-invalid' : 'form-control'} />
                                                        {touched.password && errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                                    </InputGroup>
                                                    <Row>
                                                        <Col>
                                                            <Button color="primary" type="submit" disabled={isSubmitting} className="px-4">
                                                                {isSubmitting ? <span>Ingresando... <i className="fa fa-spin fa-spinner" /></span> : <span>Ingresar</span>}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            )}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Login = connect(null, mapDispatchToProps)(Login);
export default Login;
