const initialState = [];

const AGREGAR_PRODUCTO_ACTION = 'ADD';
const EDITAR_PRODUCTO_ACTION = 'EDIT';
const BORRAR_PRODUCTO_ACTION = 'REMOVE';
const LIMPIAR_CARRITO_ACTION = 'CLEAR';

const agregarProducto = producto => {
    return { type: AGREGAR_PRODUCTO_ACTION, producto };
}

const editarProducto = producto => {
    return { type: EDITAR_PRODUCTO_ACTION, producto };
}

const borrarProducto = productoId => {
    return { type: BORRAR_PRODUCTO_ACTION, productoId };
}

const limpiarCarrito = () => {
    return { type: LIMPIAR_CARRITO_ACTION };
}

export const carritoVentaActions = {
    AGREGAR_PRODUCTO: agregarProducto,
    EDITAR_PRODUCTO: editarProducto,
    BORRAR_PRODUCTO: borrarProducto,
    LIMPIAR_CARRITO: limpiarCarrito
}

export function carritoVentaReducer(state = initialState, action) {
    switch (action.type) {
        case AGREGAR_PRODUCTO_ACTION:
            return [...state, action.producto];
        case EDITAR_PRODUCTO_ACTION:
            return state.map(p => p.productoId === action.producto.productoId ? action.producto : p);
        case BORRAR_PRODUCTO_ACTION:
            return state.filter(p => p.productoId !== action.productoId);
        case LIMPIAR_CARRITO_ACTION:
            return [];
        default:
            return state;
    }
}
