import { callAuthApi } from '../../helpers/callApi';
import pagination from '../../helpers/pagination';

const initialState = [];

const GET_OPERACIONES_REQUEST = 'GET_OPERACIONES_REQUEST';
const GET_OPERACIONES_SUCCESS = 'GET_OPERACIONES_SUCCESS';
const GET_OPERACIONES_PAGE_SUCCESS = 'GET_OPERACIONES_PAGE_SUCCESS';
const GET_OPERACIONES_FAILURE = 'GET_OPERACIONES_FAILURE';

const CREATE_OPERACION_REQUEST = 'CREATE_OPERACION_REQUEST';
export const CREATE_OPERACION_SUCCESS = 'CREATE_OPERACION_SUCCESS';
const CREATE_OPERACION_FAILURE = 'CREATE_OPERACION_FAILURE';

const AUTORIZAR_OPERACION_REQUEST = 'AUTORIZAR_OPERACION_REQUEST';
const AUTORIZAR_OPERACION_SUCCESS = 'AUTORIZAR_OPERACION_SUCCESS';
const AUTORIZAR_OPERACION_FAILURE = 'AUTORIZAR_OPERACION_FAILURE';

const getOperaciones = (page = 0) => (dispatch) => {
    var endpoint = `operaciones?take=${pagination.pageSize}&skip=${page * pagination.pageSize}`;
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [GET_OPERACIONES_REQUEST, page === 0 ? GET_OPERACIONES_SUCCESS : GET_OPERACIONES_PAGE_SUCCESS, GET_OPERACIONES_FAILURE]
    });
};

const createOperacion = operacion => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'operaciones',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(operacion),
        types: [CREATE_OPERACION_REQUEST, CREATE_OPERACION_SUCCESS, CREATE_OPERACION_FAILURE]
    });
};

const autorizarOperacion = operacionId => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: `operaciones/autorizar?operacionId=${operacionId}`,
        method: 'POST',
        types: [AUTORIZAR_OPERACION_REQUEST, AUTORIZAR_OPERACION_SUCCESS, AUTORIZAR_OPERACION_FAILURE]
    });
};

export const operacionesActions = {
    GET_OPERACIONES: getOperaciones,
    CREATE_OPERACION: createOperacion,
    AUTORIZAR_OPERACION: autorizarOperacion
};

export function operacionesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_OPERACIONES_SUCCESS:
            return action.payload;
        case GET_OPERACIONES_PAGE_SUCCESS:
            return [...state, ...action.payload];
        case CREATE_OPERACION_SUCCESS:
            return state !== initialState ? [action.payload, ...state] : initialState;
        case AUTORIZAR_OPERACION_SUCCESS:
            return state.map(o => o.operacionId === action.payload.operacionId ? action.payload : o);
        default:
            return state;
    }
}
