import { callApi } from '../../helpers/callApi';

const initialState = null;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const login = creds => (dispatch) => {
    return callApi({
        dispatch,
        endpoint: 'account',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(creds),
        types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE]
    });
};

export const authenticationActions = {
    LOGIN: login
};

export function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            window.localStorage.setItem('user', JSON.stringify(action.payload));
            // We don't change the state as this reducer just stores the user info (plus token) in the local storage. 
            return state;
        default:
            return state;
    }
}
