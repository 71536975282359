import { RSAA } from 'redux-api-middleware';

const callApi = ({ endpoint, method, headers = {}, types, body, dispatch }) => {
    let result = {};
    let failed = false;

    const action = dispatch({
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_API_URL}${endpoint}`,
            method: method,
            headers: headers,
            body: body,
            types: [
                types[0],
                {
                    type: types[1],
                    payload: (action, state, res) => {
                        return res.json().then(data => {
                            result = data;
                            return data;
                        });
                    }
                },
                {
                    type: types[2],
                    payload: (action, state, res) => {
                        if (res.status === 401) {
                            window.location = '#/login';
                        }
                        else {
                            failed = true;
                            return res.json().then(data => {
                                result = data;
                                return data;
                            });
                        }
                    }
                }
            ]
        }
    });

    return new Promise((resolve, reject) => {
        action.then(() => {
            if (failed) reject(result);
            else resolve(result);
        });
    });
}

const callAuthApi = options => {
    const user = localStorage.getItem('user') || null;
    if (user) {
        options.headers = options.headers || {};
        options.headers["Authorization"] = `Bearer ${JSON.parse(user).token}`;
    }
    return callApi(options);
};

export { callApi, callAuthApi };