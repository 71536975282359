import { callAuthApi } from '../../helpers/callApi';

const initialState = null;

const WEBSERVICE_INFO_REQUEST = 'WEBSERVICE_INFO_REQUEST';
const WEBSERVICE_INFO_SUCCESS = 'WEBSERVICE_INFO_SUCCESS';
const WEBSERVICE_INFO_FAILURE = 'WEBSERVICE_INFO_FAILURE';

const getWebServiceInfo = () => (dispatch) => {
    var endpoint = 'test/webserviceinfo';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [WEBSERVICE_INFO_REQUEST, WEBSERVICE_INFO_SUCCESS, WEBSERVICE_INFO_FAILURE]
    });
}

export const webServiceInfoActions = {
    GET_WEBSERVICE_INFO: getWebServiceInfo
}

export function webServiceInfoReducer(state = initialState, action) {
    switch (action.type) {
        case WEBSERVICE_INFO_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
