import '@coreui/coreui/dist/css/coreui.min.css';
import '@coreui/icons/css/coreui-icons.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-notifications/lib/notifications.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import configureStore from './store';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    rootElement
);