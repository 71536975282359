import { callAuthApi } from '../../helpers/callApi';

const initialState = [];

const CLIENTES_REQUEST = 'CLIENTES_REQUEST';
const CLIENTES_SUCCESS = 'CLIENTES_SUCCESS';
const CLIENTES_FAILURE = 'CLIENTES_FAILURE';

const CREATE_CLIENTE_REQUEST = 'CREATE_CLIENTE_REQUEST';
const CREATE_CLIENTE_SUCCESS = 'CREATE_CLIENTE_SUCCESS';
const CREATE_CLIENTE_FAILURE = 'CREATE_CLIENTE_FAILURE';

const EDIT_CLIENTE_REQUEST = 'EDIT_CLIENTE_REQUEST';
const EDIT_CLIENTE_SUCCESS = 'EDIT_CLIENTE_SUCCESS';
const EDIT_CLIENTE_FAILURE = 'EDIT_CLIENTE_FAILURE';

const getClientes = () => (dispatch) => {
    var endpoint = 'clientes';
    return callAuthApi({
        dispatch,
        endpoint,
        method: 'GET',
        types: [CLIENTES_REQUEST, CLIENTES_SUCCESS, CLIENTES_FAILURE]
    });
}

const createCliente = cliente => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: 'clientes',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cliente),
        types: [CREATE_CLIENTE_REQUEST, CREATE_CLIENTE_SUCCESS, CREATE_CLIENTE_FAILURE]
    });
};

const editCliente = cliente => (dispatch) => {
    return callAuthApi({
        dispatch,
        endpoint: `clientes/${cliente.clienteId}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cliente),
        types: [EDIT_CLIENTE_REQUEST, EDIT_CLIENTE_SUCCESS, EDIT_CLIENTE_FAILURE]
    });
};

export const clientesActions = {
    GET_CLIENTES: getClientes,
    CREATE_CLIENTE: createCliente,
    EDIT_CLIENTE: editCliente
}

export function clientesReducer(state = initialState, action) {
    switch (action.type) {
        case CLIENTES_SUCCESS:
            return action.payload;
        case CREATE_CLIENTE_SUCCESS:
            return [...state, action.payload];
        case EDIT_CLIENTE_SUCCESS:
            return state.map(c => c.clienteId === action.payload.clienteId ? action.payload : c)
        default:
            return state;
    }
}
