import { createStore, applyMiddleware } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './store/rootReducer';

export default function configureStore() {
    return createStore(
        rootReducer,
        applyMiddleware(apiMiddleware, thunkMiddleware)
    );
}